
import Vue from '@/vueTyped';
import AsyncImage from '@/components/AsyncImage.vue';
import HowItWorksPartial from '@/components/HowItWorksPartial.vue';
import RouteNames from '@/router/names';
import { Investigation } from '@/types';
import AuthButton from '@/components/AuthButton.vue';

export default Vue.extend({
  name: 'NolaRainLanding',

  components: {
    AsyncImage,
    AuthButton,
    HowItWorksPartial,
  },

  metaInfo: {
    title: 'New Orleans',
  },

  data() {
    return {
      heroImage: 'https://images.prismic.io/iseechange/c386403f-637c-4b7b-935e-60354355fff3_new-orleans-family.jpg?auto=compress,format',

      investigationsToShow: ['flooding', 'heat', 'plants-and-trees'],

      stories: [{
        href: 'https://stories.iseechange.org/summer-storms-reveal-that-new-orleans-has-more-than-just-a-pump-problem/',
        date: new Date('2018-03-02T18:09:44+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2018/02/wulxvsjn58m1vfac6qho-400x425.jpg',
        description: 'Summer storms reveal that New Orleans has more than just a pump problem',
        tags: ['Community Investigations', 'Deep Dive'],
      }, {
        href: 'https://stories.iseechange.org/new-orleans-floods-gentilly-resilience/',
        date: new Date('2017-08-05T04:24:37+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2017/08/34980791916_5270ae93ba_o-400x425.jpg',
        description: 'Yes. Your streets are flooding more.',
        tags: ['Community Investigations'],
      }, {
        href: 'https://stories.iseechange.org/green-water-near-the-isle-de-jean-charles-how-safe-and-how-common-are-louisianas-algal-blooms/',
        date: new Date('2016-08-06T11:07:18+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2016/08/12-400x425.jpg',
        description: 'Green water near the Isle de Jean Charles: how safe and how common are Louisiana’s algal blooms?',
        tags: ['Deep Dive'],
      }, {
        href: 'https://stories.iseechange.org/weird-march-storms-bring-historic-floods/',
        date: new Date('2016-03-31T02:33:08+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2016/04/fvolv02qp7gz93vefw94-400x425.jpeg',
        description: '“Weird” March storms bring historic floods',
        tags: ['Deep Dive'],
      }],
    }
  },

  computed: {
    investigations(): Investigation[] {
      let allInvestigations = this.$store.state.investigations.items;
      if (allInvestigations) {
        return this.investigationsToShow.map(slugToShow => {
          return allInvestigations.find(investigation => investigation.slug === slugToShow);
        }).filter(Boolean) as Investigation[];
      } else {
        return [];
      }
    }
  },

  created() {
    this.$store.dispatch('fetchInvestigations');
  },
});
