
import AuthButton from '@/components/AuthButton.vue';
import FooterLinks from '@/components/FooterLinks.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import { TRACKER_ORIGIN } from '@/config';
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    metaInfo(this: any, ) {
        return {
            title: this.pageTitle,
        };
    },

    components: {
        AuthButton,
        FooterLinks,
        MarkdownOutput,
        PrimaryLogo,
    },

    data(this: any) {
        return { pageTitle: this.t('title') };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        trackerUrl(): URL {
            const embedRootPath = this.$route.path.replace(this.$route.params.pathMatch, '*');
            const embeddedUrl = new URL(location.href);
            embeddedUrl.pathname = embedRootPath;

            const trackerPath = String(this.t('trackerPath') ?? '/flooding');
            const trackerUrl = new URL(trackerPath, TRACKER_ORIGIN);
            trackerUrl.searchParams.set('embedded-href', embeddedUrl.href);
            return trackerUrl;
        },
    },

    methods: {
        t(path: string) {
            const fullPath = `campaignLandingPages.${this.$route.params.campaign}.${path}`;
            if (this.$te(fullPath)) return this.$t(fullPath);
            if (this.$te(fullPath, 'en')) return this.$t(fullPath, 'en');
            return null;
        },
    },
});
